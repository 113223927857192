import React, { useEffect, useState } from "react";
import axios from "axios";
import "./SignINPage.css";
import Layout from "../Layout/Layout";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logInShowNav } from "../store/slices/isLogIn";
import Loder from "../Loder-page/Loder";
import { hidenav } from "../store/slices/sideNavSlice";

const SignInPage = () => {
  const [email, setEmailOrMobile] = useState(
    localStorage.getItem("getStartedEmail")
  );
  const [courseName, setCourseName] = useState(
    localStorage.getItem("courseName")
  );
  const [courseCategory, setCourseCategory] = useState(
    localStorage.getItem("courseCategory")
  );
  const [name, setName] = useState(localStorage.getItem("name"));
  const [fatherName, setFatherName] = useState(
    localStorage.getItem("fatherName")
  );
  const [emaill, setEmail] = useState(localStorage.getItem("email"));
  const [contactNo, setContactNo] = useState(localStorage.getItem("contactNo"));
  const [collegename, setcollegeName] = useState(
    localStorage.getItem("collageName")
  );
  const [universityName, setUniversityName] = useState(
    localStorage.getItem("universityName")
  );
  const apiKey = process.env.REACT_APP_API_URL;
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(null); // State variable to track loading state
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [courseId2, setCOurseId2] = useState(
    localStorage.getItem("course_id2") || ""
  );
  const [courseFees2, setCourseFees2] = useState(
    localStorage.getItem("course_fees2") || ""
  );
  const [course_id, setCourseId] = useState(
    localStorage.getItem("course_id") || ""
  );

  const [backendError, setBackendError] = useState();
  const win = window.sessionStorage;
  // const [data, setData] = useState([]);
  const handleSignIn = async () => {
    setLoading(true); // Set loading state to true when form is submitted
    const data = { email, password };
    try {
      const response = await axios.post(
        // "https://aadhyayan.aboqindia.com/admin/index.php/Api/smslogin",
        `${apiKey}admin/index.php/Api/smslogin`,
        data
      );
      setLoading(true);

      if (response.data && response.data.status === "201") {
        setBackendError(response.data.msg);
      }
      if (response.data && response.data.msg === "successfully created .") {
        const userData = response.data.res[0];
        localStorage.setItem("userId", userData.user_id);
        localStorage.setItem("name", userData.name);
        localStorage.setItem("fatherName", userData.fatherName);
        localStorage.setItem("gender", userData.gender);
        localStorage.setItem("course_id", userData.course_id);
        localStorage.setItem("contactNo", userData.mobile);
        localStorage.setItem("email", userData.email);
        localStorage.setItem("second_course_id", userData.second_course_id);
        localStorage.setItem("profile_image", userData.profileImage);
        // localStorage.clear()
        setCourseId(userData.course_id);
        if (userData.course_id > 0) {
          navigate("/dashboard/user");
          alert(userData.course_id);
          window.location.reload();
        } else if (courseId2) {
          navigate("/confirmation");
        } else {
          alert("choose");
          navigate("/choose-course");
        }

        localStorage.setItem("savedPassword", password);
        window.location.reload();
        localStorage.setItem("AdhyayanAuth", JSON.stringify({ email }));
      } else {
        console.error("Login failed:", response.data);
        alert(
          "Login failed. Please check your credentials.asd",
          response.data.msg
        );
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <div className="signIn-Page-div" onClick={() => dispatch(hidenav())}>
        {console.log("course id =====>", course_id)}
        <div className="inner-signIn-Pge">
          <div className="box-sign-container">
            <h3>Sign In</h3>
            <div className="radio-btn" style={{ fontSize: "14px" }}></div>
            <div className="input-div-conateirn">
              <div className="inner-input-div">
                <input
                  type="text"
                  placeholder="Email or mobile number"
                  value={email}
                  onChange={(e) => setEmailOrMobile(e.target.value)}
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {<p className="error-message">{backendError}</p>}
              <div className="btn-input-div">
                {loading ? ( // Render loader if loading state is true
                  // <button>Loading ...</button>
                  <Loder />
                ) : (
                  // navigate("/loder")
                  <button onClick={handleSignIn}>Sign in</button>
                )}
                <p
                  style={{ textAlign: "center", cursor: "pointer" }}
                  onClick={() => navigate("/forget-passwrd")}
                >
                  Forgot password?
                </p>
              </div>
              {console.log("====>win ", win)}
              <p>
                New to Aadhyayan?{" "}
                <span
                  className="span"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/sign-up")}
                >
                  {/* {apiKey} */}
                  Sign up now.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SignInPage;
