import React from "react";
import "./Loader.css";

const Loder = () => {
  return (
    <>
      <div className="loader-1"></div>
    </>
  );
};

export default Loder;
